/**  =====================
      Generic-class css start
========================== **/

/*====== background starts ======*/
@each $color, $value in $theme-colors {
  .bg-light-#{$color} {
    background: shift-color($value, $soft-bg-level);
    color: $value;
  }

  .bg-#{$color} {
    background: $value !important;
  }

  .icon-svg-#{$color} {
    fill: shift-color($value, $soft-bg-level);
    stroke: $value;
  }

  .text-hover-#{$color}:hover {
    color: $value !important;
  }
}
/*====== background ends ======*/
/*====== Font-size css starts ======*/
$i: 6;
@while $i<=80 {
  .f-#{$i} {
    font-size: #{$i}px;
  }
  $i: $i + 2;
}
/*====== Font-size css ends ======*/
/*====== Font-weight css starts ======*/
$i: 100;
@while $i<=900 {
  .f-w-#{$i} {
    font-weight: #{$i};
  }
  $i: $i + 100;
}
/*====== Font-weight css ends ======*/
/*====== border color css starts ======*/
@each $color, $value in $theme-colors {
  .b-#{$color} {
    border: 1px solid $value;
  }

  .border-bottom-#{$color} td {
    border-bottom: 1px solid $value;
  }

  .border-bottom-#{$color} th {
    border-bottom: 1px solid $value !important;
  }

  .fill-#{$color} {
    fill: $value;
  }
}

/*====== more bootstrap colors start ======*/
$more-colors: (
  'blue': (
    100: $blue-100,
    200: $blue-200,
    300: $blue-300,
    400: $blue-400,
    500: $blue-500,
    600: $blue-600,
    700: $blue-700,
    800: $blue-800,
    900: $blue-900
  ),
  'purple': (
    100: $purple-100,
    200: $purple-200,
    300: $purple-300,
    400: $purple-400,
    500: $purple-500,
    600: $purple-600,
    700: $purple-700,
    800: $purple-800,
    900: $purple-900
  ),
  'yellow': (
    100: $yellow-100,
    200: $yellow-200,
    300: $yellow-300,
    400: $yellow-400,
    500: $yellow-500,
    600: $yellow-600,
    700: $yellow-700,
    800: $yellow-800,
    900: $yellow-900
  )
);
@each $name, $value in $more-colors {
  $i: 100;
  @while $i<=900 {
    .bg-#{$name}-#{$i} {
      background: map-get($value, $i);
    }
    .text-#{$name}-#{$i} {
      color: map-get($value, $i);
    }
    $i: $i + 100;
  }
}
/*====== more bootstrap colors end ======*/
