.pc-sidebar {
  background: var(--pc-sidebar-background);
  width: $sidebar-width;
  position: fixed;
  top: 80px;
  bottom: 0;
  z-index: 1026;
  overflow: hidden;
  box-shadow: var(--pc-sidebar-shadow);
  border-right: var(--pc-sidebar-border);

  .navbar-wrapper {
    width: $sidebar-width;
    background: inherit;
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    &.coded-inner-navbar {
      flex-direction: column;
    }
  }

  .m-header {
    height: $header-height;
    display: flex;
    align-items: center;
    padding: 16px 24px;
    display: none;
  }

  .navbar-content {
    padding: 10px 0px;
    overflow-y: auto;
  }

  &.horizontal {
    .navbar-content {
      padding: 0px;
    }
  }

  .coded-inner-navbar {
    li {
      position: relative;
      padding-bottom: 2px;

      &.coded-menu-caption {
        font-size: 14px;
        font-weight: 500;
        padding: 25px 20px 10px;
        text-transform: capitalize;
        position: relative;
        color: var(--pc-sidebar-caption-color);
      }

      > a {
        padding: 11px 20px;
        display: block;
        border-radius: 5px;
        position: relative;
        margin-left: 16px;
        margin-right: 16px;
        color: var(--pc-sidebar-color);

        .coded-mtext {
          position: relative;
        }

        > .coded-micon {
          font-size: 18px;
          margin-right: 11px;
          height: 24px;
          display: inline-block;
          vertical-align: middle;
          text-align: center;

          + .coded-mtext {
            position: relative;
            vertical-align: middle;
            text-align: center;
          }
        }
      }

      &.coded-hasmenu {
        position: relative;

        > a {
          &:after {
            content: '\ea61';
            font-family: 'tabler-icons';
            font-size: 15px;
            border: none;
            position: absolute;
            top: 14px;
            right: 20px;
            transition: 0.3s ease-in-out;
          }
        }

        &.coded-trigger {
          > a {
            &:after {
              transform: rotate(90deg);
            }
          }
        }

        .coded-submenu {
          display: none;
        }

        &.coded-trigger.active {
          > .coded-submenu {
            display: block;
          }

          > a {
            position: relative;
          }
        }

        .coded-submenu {
          > app-nav-item,
          > app-nav-collapse {
            li {
              > a {
                text-align: left;
                padding: 10px 30px 10px 55px;
                margin: 0;
                display: block;
                font-size: 13px;
                margin-left: 16px;
                margin-right: 16px;

                &:before {
                  content: '';
                  border-radius: 50%;
                  position: absolute;
                  top: 16px;
                  left: 35px;
                  width: 5px;
                  height: 5px;
                  opacity: 1;
                }
              }

              .coded-submenu {
                > app-nav-item li {
                  > a {
                    padding: 7px 7px 7px 95px;

                    &:before {
                      left: 75px;
                    }
                  }
                }

                > app-nav-collapse li {
                  > a {
                    padding: 7px 7px 7px 95px;

                    &:before {
                      left: 55px;
                    }
                  }

                  .coded-submenu {
                    > app-nav-item li {
                      > a {
                        padding: 7px 7px 7px 115px;

                        &:before {
                          left: 95px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.coded-menu-caption {
        font-size: 14px;
        font-weight: 500;
        padding: 25px 20px 10px;
        text-transform: capitalize;
        position: relative;

        &.first-group {
          padding-top: 0px;
        }
      }

      &.disabled {
        > a {
          cursor: default;
          opacity: 0.5;
        }
      }

      app-nav-group:not(:first-child) li.coded-menu-caption:after {
        content: '';
        position: absolute;
        top: 5px;
        left: 20px;
        width: calc(100% - 40px);
        height: 1px;
        border-top: 1px solid #eee;
      }

      &.coded-hasmenu {
        .coded-submenu {
          opacity: 0;
          visibility: hidden;
          transform-origin: 50% 50%;
          transition:
            transform 0.3s,
            opacity 0.3s;
          transform-style: preserve-3d;
          transform: rotateX(-90deg);
          position: absolute;
          display: block;
        }

        &.coded-trigger {
          > .coded-submenu {
            opacity: 1;
            visibility: visible;
            transform: rotateX(0deg);
            display: grid;
            position: relative;
            padding: 7px 0px;
          }
        }
      }
    }
    app-nav-item > li.nav-item,
    > app-nav-group > app-nav-collapse > li {
      &.active,
      &.coded-trigger {
        > ul {
          &:before {
            content: '';
            position: absolute;
            left: 40px;
            top: 0;
            z-index: 1027;
            width: 1px;
            height: 100%;
            border-radius: 2px;
            background: var(--pc-sidebar-submenu-border-color);
          }
        }
      }
    }
  }

  .version {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 16px;
    cursor: pointer;

    label {
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 8px;
      padding-right: 8px;
      white-space: nowrap;
      border-radius: 16px;
    }
  }

  // menu collapse
  &.navbar-collapsed {
    width: 0px;
    height: 100%;
    transition: all 0.3s ease-in-out;

    ~ .pc-container,
    ~ .pc-footer {
      margin-left: 20px;
    }
  }

  //media open media after 1024px
  &.mob-open {
    .m-header {
      display: flex;
    }
  }

  // caption hide
  &.caption-hide {
    .coded-menu-caption {
      display: none;
    }
  }
}

//navbar-collapsed

.navbar-collapsed {
  .mobile-menu {
    transform: rotateY(-90deg);
    transform-origin: 0 0;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    .mobile-menu {
      transition-delay: 0.3s;
      transform: rotateY(0deg);
      opacity: 1;
    }
  }
}

// table media

@media (max-width: 1024px) {
  .pc-sidebar {
    left: -260px;
    box-shadow: none;
    top: 0;
    transition: all 0.15s ease-in-out;

    &.mob-open {
      left: 0;
      box-shadow: none;

      .navbar-wrapper {
        position: relative;
        z-index: 5;
        background: inherit;
      }
    }
  }
}
