/* You can add global styles to this file, and also import other style files */

/* Core Data Grid CSS */
@import 'ag-grid-community/styles/ag-grid.css';
/* Quartz Theme Specific CSS */
@import 'ag-grid-community/styles/ag-theme-quartz.css';
@import "~@fortawesome/fontawesome-free/css/all.min.css";

@import 'ngx-toastr/toastr';

@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/variables-dark';

@import 'scss/settings/color-variables';
@import 'scss/settings/theme-variables';
@import 'scss/settings/bootstrap-variables.scss';

@import 'scss/bootstrap/bootstrap.scss';

@import 'node_modules/bootstrap/scss/utilities';
@import 'node_modules/bootstrap/scss/utilities/api';

// main framework
@import 'scss/themes/generic.scss';
@import 'scss/themes/general.scss';

// Layouts
@import 'scss/themes/layouts/layouts';

// Componants
@import 'scss/themes/components/components';

// pages
@import 'scss/themes/pages/page.scss';

@import 'scss/themes/customizer';
@import 'scss/style-preset.scss';

// fonts
@import url('scss/fonts/berry/berry-icons.css'); /** custom font icons - berry **/
@import 'scss/fonts/tabler-icons.min.css';
@import 'scss/fonts/phosphor/duotone/style.css';

@import "~@ng-select/ng-select/themes/default.theme.css";

@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";

@import  "tabulator-tables";

@import 'ngx-toastr/toastr-bs5-alert';
// Import de estilos de assets COMPONENTES
@import 'assets/sass/components/components.scss';


.center-div{
    display: flex;
    align-items: center;
    justify-content: center;
}

.title-modal-mini{
    font-size: 17px;
    margin-top: -15px;
    font-weight: 500;
}

.i-collapse-grid{
    font-size: 17px !important;
    color: #0d6886;
}
.accordion-button {
    background: #a9afbd !important;
    color: black !important;
  }
  
#table-tabulator-detalle-RQ .tabulator-row .tabulator-cell {
    height: 40px !important;
}

#table-tabulator-detalle-RQ .tabulator .tabulator-row {
    height: 40px;
    font-size: 15px !important;
}

.tabulator-row .tabulator-cell.tabulator-editing {
    border: none !important;
}

.tabulator-row .tabulator-cell.tabulator-editing input[type="text"]{
    outline-color: #93d1e5;
}

.classGridEditorColumn{
    background: #caffceb3;
    font-weight: 500;
}

.classGridStyleFilter .tabulator-col-content{
    display: block !important;
}

.classGridStyleFilter .tabulator-header-filter{
    padding: 0px 15px;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #06b0c5;
    border-top: 3px solid #04afc3;
    font-weight: 500;
}
.subTable  .tabulator-headers{
    display: flex;
}

.check-select-row{
    height: 18px !important;
    width: 18px;
}
#table-tabulator-detalle-RQ .tabulator-row.tabulator-row-even {
    background-color: #f3f3f3 !important;
    border-bottom: 1px #baadad solid;
}
#table-tabulator-detalle-RQ .tabulator-row.tabulator-row-odd {
    background-color: #f3f3f3 !important;
    border-bottom: 1px #baadad solid;
}

#table-tabulator-detalle-RQ .tabulator-row .tabulator-cell {
    border-style: solid;
    border-width: 0px 1px 1px 1px;
    border-color: #959595;
}

.skin-blue .sidebar-menu .treeview-menu>li>a.submenu.seccion-selected {
    background: #155f67 !important;
    color: white !important;
}

.ag-header-cell.ag-focus-managed.ag-header-cell-sortable {
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.ag-theme-alpine .ag-cell {
    padding-left: 8px !important;
    padding-right: 8px !important;
}

// Tabulator
.tabulator-col-group .tabulator-col-title {
    text-align: center !important;
}

.tabulator-col[aria-sort=none] {
    // height: 100%;
    display: inline-grid;
}

.tabulator-col[aria-sort=none][role=columnheader] .tabulator-col-content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tabulator-col-title {
    font-weight: bold;
}

.tabulator-bold { 
    background-color: #ccc;
    font-weight: bold;
}


.tabulator-explosion .tabulator-headers {
    height: 42px;
    padding-top: 6px;
}

.tabulator-explosion .tabulator-col-title {
    font-size: 16px;
}

.tabulator .tabulator-header .tabulator-col {
    background: #caf2ff !important;
    text-align: center !important;
    color: #0b6785;
    font-weight: 500;
    border-right: 1px solid #93d1e5 !important;
    font-size: 13px;
}

.tabulator-row .tabulator-cell {
    border-style: solid;
    border-width: 0px 1px 1px 0px;
    border-color: transparent;
    font-size: 14px;
    color: #000;
    border-right: 1px solid #e7e7e7 !important;
    text-transform: capitalize;
   // height: 30px !important;
    //padding: 9px 4px !important;
}

.tabulator-row .tabulator-editing {
    padding: 0 !important;
}


.tabulator-row.tabulator-row-even {
    background-color: #f3f3f3 !important;
}

.tabulator-row.tabulator-selected {
    background-color: #caf2ff !important;
}

.tabulator {
    border: 2px solid #c5c5c5 !important;
    background-color: #fff !important;
    border-radius: 7px;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
}

.tabulator .tabulator-header {
    border-bottom: 1px solid #c5c5c5 !important;
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-group .tabulator-col-group-cols {
    border-top: 1px solid #93d1e5 !important;
}

.tabulator .tabulator-row {
   // height: 35px;
}

.tabulator .tabulator-footer .tabulator-calcs-holder {
    border: none !important;
}

.tabulator .tabulator-footer {
    border-top: none !important;
    background-color: #f8f8f8 !important;
}

.tabulator-row.tabulator-selectable:hover {
    background-color: #E1F5FE !important;
}

.tabulator .tabulator-footer .tabulator-page {
    border: none !important;
    color: #0b6785;
}

.tabulator .tabulator-footer .tabulator-page:disabled {
    opacity: .9;
}

.tabulator .tabulator-footer .tabulator-page.active {
    color: #fff !important;
    background: #419dbb;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable .tabulator-col-content {
    height: 100%;
    display: inline-grid;
    width: 100%;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable .tabulator-col-title {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tabulator .tabulator-header {
    background-color: #caf2ff !important;
}

.tabulator-header-filter input {
    font-size: 13px !important;
    padding: 3px 10px !important;
}

.tabulator-row.tabulator-group {
    background: #f5f5f5 !important;
    font-size: 13px;
}

.tabulator-row.tabulator-group span {
    color: #419dbb !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.tabulator-calcs.tabulator-calcs-bottom {
    border-top: 2px solid #0d9bca !important;
    background: #eefbff !important;
}

.tabulator-row.tabulator-group.tabulator-group-level-0 {
    padding: 10px;
    height: 40px;
}

.tabulator-row.tabulator-group.tabulator-group-level-1 {
    padding-left: 60px !important;
    padding: 10px;
    height: 40px;
    border-top: none;
    background: #fbfbfb !important;
}

.tabulator-row.tabulator-group.tabulator-group-level-2 {
    padding-left: 90px !important;
    padding: 10px;
    height: 40px;
    border-top: none;
    background: #fbfbfb !important;
}

.tabulator-row.tabulator-group.tabulator-group-level-3 {
    padding-left: 120px !important;
    padding: 10px;
    height: 40px;
    border-top: none;
    background: #fbfbfb !important;
}

.tabulator-row.tabulator-group.tabulator-group-level-4 {
    padding-left: 150px !important;
    padding: 10px;
    height: 40px;
    border-top: none;
    background: #fbfbfb !important;
}

.bStyleTotalesTabulator {
    float: right;
}

.bStyleTotalesTabulator>div {
    font-weight: 500;
    color: black;
    display: inline-flex;
    font-size: 11px;
    position: relative;
    top: -7px;
    right: 10px;
}

.bStyleTotalesTabulator b {
    font-size: 11px;
    margin-top: -4px;
    color: #419dbb;
}

.bStyleTotalesTabulator div>div {
    margin-left: 20px;
    display: grid;
}

.styleRowTableAlt {
    background: #eeeeee;
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.tabulator-calcs .totRed.tabulator-cell {
    color: red !important;
}

.eye {
    color: #f19e22;
}

.pencil {
    color: #0cb4c9;
}

.trash {
    color: #e40606;
}


input[inputmode="numeric"] {
    text-align: end;
}


.border-radius-r-0 {
    border-radius: 0.25rem 0px 0px 0.25rem !important;
}

.border-radius-l-0 {
    border-radius: 0px 0.25rem 0.25rem 0px !important;
}

.border-radius-0 {
    border-radius: 0px !important;
}

.border-radius-7 {
    border-radius: 7px !important;
}

.border-l-0 {
    border-left: none !important;
    margin-left: 1px !important;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #f7f7f7 !important;
    opacity: 1;
}

.border-none {
    border: none !important;
}

.font-bold {
    font-weight: 500;
}

.ag-input-field-input {
    height: 28px !important;
}

.grid-font-17 .ag-cell {
    font-size: 17px !important;
}

.grid-font-14 .ag-cell {
    font-size: 14px !important;
}

.grid-font-13-header .ag-header-cell {
    font-size: 13px !important;
}

.grid-columns-color .ag-body-viewport [col-id="valor_devengado"] {
    background-color: #cef8da !important;
}

.grid-columns-color .ag-body-viewport [col-id="valor_deducible"] {
    background-color: #fbe9e7 !important;
}

.border-blue {
    border-color: #24c1e3 !important;
}


.ag-theme-alpine .ag-icon {
    font-family: "agGridAlpine" !important;
}

.ag-header-cell-label {
    justify-content: center;
}

.cursor-pointer {
    cursor: pointer;
}


/* ***** CHECK/RADIO STYLE BASIC ***** */

.checkbox input[type=checkbox] {
    position: relative;
    cursor: pointer;
    margin-right: 10px;
}

.checkbox input[type=checkbox]:before {
    content: "";
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: -2px;
    left: 0;
    background-color: #e9e9e9;
    border-radius: 5px;
}

.checkbox input[type=checkbox]:checked:before {
    content: "";
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: -2px;
    left: 0;
    background-color: #22c3d7;
    border-radius: 5px;
}

.checkbox input[type=checkbox]:checked:after {
    content: "";
    display: block;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 7px;
}


.btn-edit-grid {
    padding: 5px 5px;
    border-radius: 50px;
    border: none !important;
    background: #20c2d6;
    color: white;
    width: 40%;
    margin-left: 10px;
}

.btn-delete-grid {
    border: none !important;
    background: #f4433600;
    color: red;
    width: 70%;
    margin-left: 10px;
    outline: none !important;
}

.btn-delete-grid-sm {
    padding: 5px 5px;
    border-radius: 50px;
    border: none !important;
    // background: #f44336;
    color: rgb(255, 1, 1);
    width: 100%;
    margin: 0px !important;
    padding: 5px !important;
    margin-top: 2px !important;
}



.color-white {
    color: white !important;
}

.color-blue {
    color: #09b2c7 !important;
}

.color-gray {
    color: #b2b2b2 !important;
}

#btn-filter-menu {
    height: 25px;
    padding: 4px 8px 5px 11.5px;
    border-radius: 5px;
    opacity: 0.9;
}

#btn-filter-menu:focus,
#btn-filter-menu:active {
    outline: none !important;
    box-shadow: none;
}

#btn-filter-menu i {
    font-size: 11px !important;
    margin: 0px;
}

.font-9 {
    font-size: 9px;
}

.font-11 {
    font-size: 11px !important;
}

.font-12 {
    font-size: 12px !important;
}

.font-13 {
    font-size: 13px !important;
}

.font-14 {
    font-size: 14px !important;
}

.font-15 {
    font-size: 15px !important;
}

.font-17 {
    font-size: 17px !important;
}

.font-18 {
    font-size: 18px !important;
}

.font-20 {
    font-size: 20px !important;
}

.center-div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.center-div-y {
    display: flex;
    align-items: center;
}

.center-div-x {
    display: flex;
    justify-content: center;
}

.title-render {
    //position: absolute;
    //top: -14px;
    background: white;
    color: #828282;
    padding: 10px 25px 10px 25px;
    border-radius: 0px 0px 15px 15px;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
    font-size: 21px; width: 100%; text-align: center;
}

.title-render label {
    font-size: 10px;
    margin: 0px;
    margin-left: -10px !important;
    margin-bottom: 2px !important;
    color: #b9c3cf;
}

.title-modal {
    width: 100%;
    padding: 7px 0px;
    font-size: 20px;
    margin-bottom: 20px;
    color: #09b2c7;
    text-align: center;
}

.title-modal-basic {
    width: 100%;
    padding: 7px 0px;
    font-size: 19px;
    margin-bottom: 20px;
    color: #b2b2b2;
    border-bottom: 1px solid #b2b2b287;
    margin-top: 0.25rem;
}

.title-modal-2 {
    width: fit-content;
    background: white;
    border-radius: 25px;
    padding: 10px 40px;
    font-size: 20px;
    margin-bottom: 25px;
    color: #6c75a3;
    box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.10) !important;
}

.title-modal-2-basic {
    padding: 10px 40px;
    font-size: 20px;
    color: #6c75a3;
}

.title-modal small {
    font-size: 12px;
    margin-left: 10px;
    color: #6c757d !important;
}

.box {
    padding: 15px 20px !important;
    //box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    box-shadow: 0 0.2rem 0.3rem rgba(0, 0, 0, 0.15) !important;
    border: 1px solid #e2e2e2a6 !important;
    margin-left: 0px !important;
    border-radius: 5px !important;
}

.p-10-25 {
    padding: 10px 25px !important;
}

.p-y-6 {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}

.m-t-14px {
    margin-top: 14px;
}


.content-form input,.content-form select,.content-form,.content-form .form-control.selection.dropdown {
    font-size: 15px !important;
    padding: 4px 12px !important;
    height: auto !important;
    line-height: 1.5 !important;
    border-radius: 0.2rem !important;
    border: 1px solid #ced4da !important;
}

div.inps-small input,
div.inps-small select,
div.inps-small,
div.inps-small .form-control.selection.dropdown, 
input.inps-small {
    padding: 4px 10px !important;
    font-size: 14px;
}

.ng-value-container {
    padding-top: 2px;
}

.ag-root-wrapper {
    border-color: #e8e8e8 !important;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075)!important;
    border-radius: 10px !important;
}

.ag-header {
    background-color: #ffffff !important;
    border: none !important;
    font-size: 15px;
    text-transform: capitalize;
    border-bottom: 2px solid #0cb4c942 !important;
}

.grid-header-color .ag-header {
    background: #09c1dd !important;
}

.ag-header .ag-header-cell {
    font-weight: 400;
}

.grid-header-color .ag-header .ag-header-cell {
    color: white;
}

.content-form label, .modal-body label {
    color: #696a6b;
    font-size: 15px;
    font-weight: 500;
}

.modal-header {
    border: none;
    background: linear-gradient(60deg, #36c7e6, #0eb9e0) !important;
    color: white;
    border-radius: 0px;
    box-shadow: 0 .2rem 1rem rgba(0, 0, 0, .15)!important;
    z-index: 1;
    padding: 25px;
}

.modal-header .close {
    padding: 0 1.5rem !important;
    color: white;
    font-size: 40px !important;
}

.modal-title {
    font-weight: 400 !important;
    margin: 0;
    margin-left: 15px;
}

.modal-body {
    background: white;
    padding: 1.5rem 2rem;
    border-radius: 0px;
}

.modal-body label,
.content-form label {
    color: #696a6b;
    font-size: 15px;
    font-weight: 500;
}
app-render .content-form label{
    color: #b7a8a8;
}

.modal-body .btn,
.content-form .btn {
    font-size: 15px !important;
    font-weight: 400 !important;
    border-radius: 5px !important;
    padding: 9px 17px !important;
    border: none !important;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    margin: 0px 7px;
    float: right;
    margin-top: 10px;
}

.modal-body .btn.btn-primary,
.modal-body .btn.btn-success,
.content-form .btn.btn-primary,
.content-form .btn.btn-success {
    padding: 9px 20px !important;
}

.modal-body .btn.btn-default,
.content-form .btn.btn-default {
    padding: 8px 12px !important;
}

.modal-body .btn.btn-default i,
.content-form .btn.btn-default i {
    display: none;
}

.modal-w-90{
    width: 90%;
    max-width: 90%;
}
.modal-w-70{
    width: 70%;
    max-width: 70%;
}

.tabulator-cell[tabulator-field=Observaciones] {
    font-size: 10px !important;
}

.tabulator-explosion .tabulator-headers {
    padding-top: 6px;
}

.tabulator-explosion .tabulator-col-title {
    font-size: 15px;
}

.tabulator .tabulator-header .tabulator-col {
    background: #caf2ff !important;
    text-align: center !important;
    color: #0b6785;
    font-weight: 500;
    border-right: 1px solid #93d1e5 !important;
    font-size: 13px;
}

.tabulator-row .tabulator-cell {
    border-style: solid;
    border-width: 0px 1px 1px 0px;
    border-color: transparent;
    font-size: 14px;
    color: #000;
    border-right: 1px solid #e7e7e7 !important;
    text-transform: capitalize;
    // height: 30px !important;
    //padding: 9px 4px !important;
}

.tabulator-row .tabulator-editing {
    padding: 0 !important;
}


.tabulator-row.tabulator-row-even {
    background-color: #f3f3f3 !important;
}

.tabulator-row.tabulator-selected {
    background-color: #caf2ff !important;
}

.tabulator {
    border: 2px solid #c5c5c5 !important;
    background-color: #fff !important;
    border-radius: 7px;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
}

.tabulator .tabulator-header {
    border-bottom: 1px solid #c5c5c5 !important;
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-group .tabulator-col-group-cols {
    border-top: 1px solid #93d1e5 !important;
}

.tabulator .tabulator-row {
    // height: 35px;
}

.tabulator .tabulator-footer .tabulator-calcs-holder {
    border: none !important;
}

.tabulator .tabulator-footer {
    border-top: none !important;
    background-color: #f8f8f8 !important;
}

.tabulator-row.tabulator-selectable:hover {
    background-color: #E1F5FE !important;
}

.tabulator .tabulator-footer .tabulator-page {
    border: none !important;
    color: #0b6785;
}

.tabulator .tabulator-footer .tabulator-page:disabled {
    opacity: .9;
}

.tabulator .tabulator-footer .tabulator-page.active {
    color: #fff !important;
    background: #419dbb;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable .tabulator-col-content {
    height: 100%;
    display: inline-grid;
    width: 100%;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable .tabulator-col-title {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tabulator .tabulator-header {
    background-color: #caf2ff !important;
}

.tabulator-header-filter input {
    font-size: 13px !important;
    padding: 3px 10px !important;
    background: rgb(255 255 255 / 81%);
    border: 1px solid #cfcfcf;
}

.tabulator-row.tabulator-group {
    background: #f5f5f5 !important;
    font-size: 13px;
}

.tabulator-row.tabulator-group span {
    color: #419dbb !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.tabulator-calcs.tabulator-calcs-bottom {
    border-top: 2px solid #0d9bca !important;
    background: #eefbff !important;
}

.tabulator-row.tabulator-group.tabulator-group-level-0 {
    padding: 10px;
    height: 40px;
}

.tabulator-row.tabulator-group.tabulator-group-level-1 {
    padding-left: 60px !important;
    padding: 10px;
    height: 40px;
    border-top: none;
    background: #fbfbfb !important;
}

.tabulator-row.tabulator-group.tabulator-group-level-2 {
    padding-left: 90px !important;
    padding: 10px;
    height: 40px;
    border-top: none;
    background: #fbfbfb !important;
}

.tabulator-row.tabulator-group.tabulator-group-level-3 {
    padding-left: 120px !important;
    padding: 10px;
    height: 40px;
    border-top: none;
    background: #fbfbfb !important;
}

.tabulator-row.tabulator-group.tabulator-group-level-4 {
    padding-left: 150px !important;
    padding: 10px;
    height: 40px;
    border-top: none;
    background: #fbfbfb !important;
}

.bStyleTotalesTabulator {
    float: right;
}

.bStyleTotalesTabulator>div {
    font-weight: 500;
    color: black;
    display: inline-flex;
    font-size: 11px;
    position: relative;
    top: -7px;
    right: 10px;
}

.bStyleTotalesTabulator b {
    font-size: 11px;
    margin-top: -4px;
    color: #419dbb;
}

.bStyleTotalesTabulator div>div {
    margin-left: 20px;
    display: grid;
}

.styleRowTableAlt {
    background: #eeeeee;
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.tabulator-calcs .totRed.tabulator-cell {
    color: red !important;
}

.tabulator-col-group .tabulator-col-title {
    text-align: center !important;
}

.tabulator-col[aria-sort=none] {
    // height: 100%;
    display: inline-grid;
}

.tabulator-col[aria-sort=none][role=columnheader] .tabulator-col-content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tabulator-col-title {
    font-weight: bold;
}

.tabulator-bold {
    background-color: #ccc;
    font-weight: bold;
}

#table-tabulator-detalle-RQ .tabulator-row.tabulator-row-even {
    background-color: #f3f3f3 !important;
    border-bottom: 1px #baadad solid;
}

#table-tabulator-detalle-RQ .tabulator-row.tabulator-row-odd {
    background-color: #f3f3f3 !important;
    border-bottom: 1px #baadad solid;
}

#table-tabulator-detalle-RQ .tabulator-row .tabulator-cell {
    border-style: solid;
    border-width: 0px 1px 1px 1px;
    border-color: #959595;
}

#table-tabulator-detalle-RQ .tabulator-row .tabulator-cell {
    height: 40px !important;
}

#table-tabulator-detalle-RQ .tabulator .tabulator-row {
    height: 40px;
    font-size: 15px !important;
}

.tabulator-row .tabulator-cell.tabulator-editing {
    border: none !important;
}

.tabulator-row .tabulator-cell.tabulator-editing input[type="text"] {
    outline-color: #93d1e5;
}

.classGridEditorColumn {
    background: #caffceb3;
    font-weight: 500;
}

.classGridStyleFilter .tabulator-col-content {
    display: block !important;
}

.classGridStyleFilter .tabulator-header-filter {
    padding: 0px 15px;
}

.subTable .tabulator-headers {
    display: flex;
}

.tabulator-row.tabulator-moving {
    z-index: 150000 !important;
}

.center-header-grid {
    text-align: center;
    text-align: center;
  }
  
  .hide {
    display: none;
  }
  
  .modal-full {
    width: 90% !important;
    max-width: 90%;
  }