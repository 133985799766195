.inputProperty {
    border: 1px #cccccc solid;
    outline: none;
    width: 97%;
    border-radius: 5px;
    padding: 3px 6px;
}

.btn-times {
    /* width: 10px; */
    background: transparent;
    border: none;
    border-radius: 8px;
    outline: none;
}


/* ***** CHECK STYLE ***** */

.ios-checkbox-label,
.ios-checkbox-label:before,
.ios-checkbox-label:after {
    box-sizing: border-box;
    transition: .25s ease-in-out;
    outline: none;
}

#toggles {
    width: 60px;
    text-align: center;
    margin: 0px auto;
}

#toggles.small {
    width: 50px;
}

.ios-toggle,
.ios-toggle:active {
    position: absolute;
    //top: -5000px;
    height: 0;
    width: 0;
    opacity: 0;
    border: none;
    outline: none;
}

.ios-checkbox-label {
    display: block;
    position: relative;
    padding: 10px;
    margin-bottom: 0px;
    font-size: 12px;
    line-height: 16px;
    width: 100%;
    height: 25px;
    border-radius: 18px;
    background: #f8f8f8;
    cursor: pointer;
    margin-top: 9px;
}

#toggles.small .ios-checkbox-label {
    height: 20px;
}

.ios-checkbox-label:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    line-height: 34px;
    text-indent: 40px;
    height: 25px;
    width: 25px;
    border-radius: 100%;
    top: 0px;
    left: 0px;
    right: auto;
    background: white;
    box-shadow: 0 3px 3px rgba(0, 0, 0, .2), 0 0 0 2px #dddddd;
}

#toggles.small .ios-checkbox-label:before {
    height: 20px;
    width: 20px;
}

.ios-checkbox-label:after {
    content: attr(data-off);
    display: block;
    position: absolute;
    z-index: 0;
    top: -5px;
    left: -50px;
    padding: 10px;
    height: 100%;
    width: 50px;
    text-align: right;
    color: #bfbfbf;
    white-space: nowrap;
}

.no-text:after,
.no-text::before {
    content: '' !important;
}

#toggles.small .ios-checkbox-label:after {
    display: none;
}

.ios-toggle+.ios-checkbox-label {
    box-shadow: inset 0 0 0 0px #57a7fd, 0 0 0 2px #dddddd;
}

.ios-toggle:checked+.ios-checkbox-label {
    box-shadow: inset 0 0 0 20px #57a7fd, 0 0 0 2px #57a7fd;
}

.ios-toggle:checked+.ios-checkbox-label:before {
    left: calc(100% - 25px);
    box-shadow: 0 0 0 2px transparent, 0 3px 3px rgba(0, 0, 0, .3);
}

#toggles.small .ios-toggle:checked+.ios-checkbox-label:before {
    left: calc(100% - 20px);
}

.ios-toggle:checked+.ios-checkbox-label:after {
    content: attr(data-on);
    left: 60px;
    width: 36px;
    color: #57a7fd;
}
.label-input-switch {
    padding-top: 0px !important;
    top: -26px !important;
    width: 100%;
    // text-align: center !important;
}
// .toggle-down{
//     margin-top: 31px !important;
// }