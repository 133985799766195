.form-group {
    position: relative;
    margin-bottom: 1rem;
}


// Customization
.form-control {
    padding: 6px 0.75rem !important;
    // color: #fff;

    &:focus {
        outline: none;
        box-shadow: none;
    }

    &::placeholder {
        color: transparent;
    }

    &:focus,
    &:not(:placeholder-shown) {
        &~.form-label {
            top: -0.8rem;
            left: 0.2rem;
            font-size: 0.875rem;
            padding: 0 0.25rem;
            background: #ffffff;
            color: #696a6b;

        }
    }

    &:focus~.form-label {
        // color: #fff;
    }
}

.form-label {
    position: absolute;
    left: 0.75rem;
    top: 6px;
    transition: all 0.2s ease-out;
    pointer-events: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

input[type=number] {
    -moz-appearance: textfield;
}

button.btn.btn-outline-light.float-end {
    background: #cdcccc;
}

[part=preset-1] .form-select:focus,
[part=preset-1] .form-select:hover,
[part=preset-1] .form-control:focus,
[part=preset-1] .form-control:hover {
    border-color: var(--bs-form-valid-border-color);
    box-shadow: none;
}

// Quitar icono verde y rojo de los inputs
.form-control {
    &.is-valid {
        border-color: var(--bs-success);
        background-image: none  !important;
        padding-right: inherit;
    }
    
    &.is-invalid {
        border-color: var(--bs-danger);
        padding-right: inherit;
        background-image: none  !important;
        // background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2350cd89' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
        // background-repeat: no-repeat;
        // background-position: right calc(3.275em + 0.3875rem) center;
        // background-size: calc(0.75em + 0.775rem) calc(0.75em + 0.775rem);
    }
}