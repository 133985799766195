
.form-group:not(.select-normal)  {
    .ng-select {
        &.is-valid {
            & .ng-select-container {
                border-color: var(--bs-success);
                // background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2350cd89' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
                // background-repeat: no-repeat;
                // background-position: right calc(3.275em + 0.3875rem) center;
                // background-size: calc(0.75em + 0.775rem) calc(0.75em + 0.775rem);
            }
        }

        &.is-invalid {
            & .ng-select-container {
                border-color: var(--bs-danger);
                // background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f1416c'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f1416c' stroke='none'/%3e%3c/svg%3e");
                // background-repeat: no-repeat;
                // background-position: right calc(1.375em + 0.3875rem) center;
                // background-size: calc(0.75em + 0.775rem) calc(0.75em + 0.775rem);
            }
        }

        .ng-select-container {
            padding-top: 1.65rem;
            padding-bottom: 0.2rem;
            height: 51px !important;
        }

        &~label.form-label {
            padding-top: 0.2rem;
        }

        & .ng-arrow-wrapper {
            position: absolute !important;
            top: 16px;
            right: 0;
        }

        & .ng-clear-wrapper {
            position: absolute !important;
            top: 16px;
            right: 24px;
        }
    }

    .ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
        border-color: var(--bs-success);
        box-shadow: inset 0 1px 1px rgb(0 255 7 / 8%), 0 0 0 3px rgb(40 255 0 / 10%);
    }

    .ng-select-field-edit {
        padding-left: 0;
        padding-right: 0;
    }
}