// Archivo: utilities.scss

// Utilidades de padding y margen
$spacers: (
  0: 0,
  1: 0.25rem,
  2: 0.5rem,
  3: 1rem,
  4: 1.5rem,
  5: 3rem
);

@mixin generate-spacing-utilities($property, $short-name) {
  @each $key, $value in $spacers {
    .#{$short-name}-#{$key} {
      #{$property}: #{$value} !important;
    }
  }
}

// Generar clases de padding
@include generate-spacing-utilities(padding-right, pr);
@include generate-spacing-utilities(padding-left, pl);
@include generate-spacing-utilities(padding-top, pt);
@include generate-spacing-utilities(padding-bottom, pb);

// Generar clases de margen
@include generate-spacing-utilities(margin-right, mr);
@include generate-spacing-utilities(margin-left, ml);
@include generate-spacing-utilities(margin-top, mt);
@include generate-spacing-utilities(margin-bottom, mb);
@include generate-spacing-utilities(margin, m);
@include generate-spacing-utilities(padding, p);

// Utilidades de colores
$colors: (
  primary: #007bff,
  secondary: #6c757d,
  success: #28a745,
  danger: #dc3545,
  warning: #ffc107,
  info: #17a2b8,
  light: #f8f9fa,
  dark: #343a40
);

@each $name, $color in $colors {
  .text-#{$name} {
    color: #{$color} !important;
  }
  .bg-#{$name} {
    background-color: #{$color} !important;
  }
}

// Utilidades de flexbox
.flex {
  display: flex !important;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
